import { useMemo } from 'react';
import { Player } from '../interfaces/Player';
import { Container, Table } from 'react-bootstrap';

interface DashboardPlayersProps {
    userName: string;
    players: Player[];
    roomId: string;
}

const DashboardPlayers = ({ userName, players, roomId }: DashboardPlayersProps) => {

    const sortedPlayers = useMemo(() => {
        return [...players].sort((a, b) => (b.points || 0) - (a.points || 0));
    }, [players]);

    return (
        <Container fluid>
            <Table className="table-dark text-center" responsive striped="columns" borderless>
                <tbody>
                    <tr>
                        <td className="text-info">Sala</td>
                        {sortedPlayers.map((player: Player, index) => {
                            if (player.name === userName) {
                                return <td key={index}>{player.name}{<strong> (Tú)</strong>}{' ( '}{player.points}{' ) '}</td>
                            } else {
                                return <td key={index}>{player.name}{' ( '}{player.points}{' ) '}</td>
                            }
                        })}
                    </tr>

                    <tr>
                        <td className="text-info">{roomId}</td>
                        {sortedPlayers.map((player: Player, index) => {
                            if (player.bet != null) {
                                return <td key={index}><strong>A:</strong> {player.bet} / <strong>G:</strong> {player.carried}</td>
                            } else {
                                return <td key={index}><strong>A:</strong> X / <strong>G:</strong> {player.carried}</td>
                            }
                        })}
                    </tr>
                </tbody>
            </Table>
        </Container>
    );
};

export default DashboardPlayers;
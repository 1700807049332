import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormFindCustomGame from '../components/FormFindCustomGame';

function HomePage() {

  const navigate = useNavigate();
  const user = JSON.parse(window.localStorage.getItem("atenaie-user")!).user;
  const [showFormFindCustomGame, setShowFormFindCustomGame] = useState(false);

  const handleFindGame = () => {
    navigate('/game', { state: { userName: user.userName } });
  };

  const handleCreateCustomGame = () => {
    navigate('/game', { state: { userName: user.userName, customGame: true } });
  };

  const closeFormFindCustomGame = () => {
    setShowFormFindCustomGame(false);
  }

  const openFormFindCustomGame = async () => {
    setShowFormFindCustomGame(true)
  }

  return (
    <div className="container d-flex flex-column align-items-center">
      <div className="btn-group-vertical">
        <h2>Bienvenido {user.userName}</h2>
      </div>
      <h1 className="text-center mt-4 mb-4">Elige una opción</h1>
      <div className="btn-group-vertical">
        <button className="btn btn-primary btn-block mt-2" onClick={handleFindGame}>
          Buscar partida
        </button>
        <button className="btn btn-primary btn-block mt-2" onClick={handleCreateCustomGame}>
          Crear partida personalizada
        </button>
        <button className="btn btn-primary btn-block mt-2" onClick={openFormFindCustomGame}>
          Buscar partida personalizada
        </button>
      </div>

      <div>
        {<FormFindCustomGame showForm={showFormFindCustomGame} closeFormFindCustomGame={closeFormFindCustomGame} />}
      </div>

    </div>
  );
}

export default HomePage;
import React, { useState, useEffect } from 'react';
import { Card } from '../interfaces/Card';
import ProgressBar from 'react-bootstrap/ProgressBar';

const PLAY_CARD_TIME = 50;

interface PlayCardTimerProps {
    myTurn: boolean;
    playCard: (idCard: number) => void;
    playerCards: Card[];
}

const PlayCardTimer: React.FC<PlayCardTimerProps> = ({ myTurn, playCard, playerCards }) => {
    const [timeRemaining, setTimeRemaining] = useState(PLAY_CARD_TIME);
    const [timeout, setTimeout] = useState<NodeJS.Timeout>();

    useEffect(() => {
        setTimeRemaining(PLAY_CARD_TIME);

        if (myTurn) {
            setTimeout(setInterval(() => {
                setTimeRemaining(prevTime => prevTime - 1);
            }, 1000))
        }

        return () => {
            clearInterval(timeout);
        };
    }, [myTurn]);

    useEffect(() => {
        if (timeRemaining === 0) {
            clearInterval(timeout);

            const firstPlayableCard = playerCards.find((card) => card.playable);
            if(firstPlayableCard){
                playCard(firstPlayableCard.id);
            }
        }
    }, [timeRemaining]);

    return (
        <div>
            {myTurn && (
                <div className="mt-3 mb-3">
                    <ProgressBar animated min={0} max={PLAY_CARD_TIME} now={timeRemaining} label={`${timeRemaining} segundos`} />
                </div>
            )}
        </div>
    );
};

export default PlayCardTimer;

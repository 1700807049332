import { Card, CardPlayed } from "../interfaces/Card";

export const playableCards = (playerCards: Card[], cardsPlayedForPlayableFlag: CardPlayed[], myTurn: boolean, blockPlayerCards: boolean): Card[] => {

    let cardsWithPlayableFlag: Card[];

    if (myTurn && !blockPlayerCards) {
        if (cardsPlayedForPlayableFlag.length > 0) {
            let firstCardPlayedNotSpecial = cardsPlayedForPlayableFlag.find(card => !card.special);

            if (firstCardPlayedNotSpecial) {
                let typeCardHaveToPlay = firstCardPlayedNotSpecial.type;
                let hasSome = playerCards.some(card => card.type === typeCardHaveToPlay);

                if (hasSome) {
                    cardsWithPlayableFlag = playerCards.map((card) => {
                        if (card.type === typeCardHaveToPlay || card.special === true) {
                            return { ...card, playable: true };
                        } else {
                            return { ...card, playable: false };
                        }
                    });
                } else {
                    cardsWithPlayableFlag = playerCards.map((card) => {
                        return { ...card, playable: true };
                    });
                }
            } else {
                cardsWithPlayableFlag = playerCards.map((card) => {
                    return { ...card, playable: true };
                });
            }
        } else {
            cardsWithPlayableFlag = playerCards.map((card) => {
                return { ...card, playable: true };
            });
        }
    } else {
        cardsWithPlayableFlag = playerCards.map((card) => {
            return { ...card, playable: false };
        });
    }

    return cardsWithPlayableFlag;
}
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../services/RestServer';
import FormNewUser from '../components/FormNewUser';

function LoginPage() {

  const [username, setUsername] = useState('');
  const [usernameGuest, setUsernameGuest] = useState('');
  const [password, setPassword] = useState('');
  const [showNewUserForm, setShowNewUserForm] = useState(false);
  const navigate = useNavigate();

  const handleUsernameGuestChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsernameGuest(event.target.value);
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleGuestPlay = () => {
    if (usernameGuest != "") {
      navigate('/game', { state: { userName: usernameGuest, customGame: false } });
    } else {
      alert("Tienes que introducir un nombre");
    }
  };

  const handleLogin = async () => {
    try {
      if (await login(username, password)) {
        navigate('/home');
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const handleNewUser = async () => {
  //   setShowNewUserForm(true);
  // };

  const closeFormNewUser = () => {
    setShowNewUserForm(false);
  }

  useEffect(() => {
    if (window.localStorage.getItem("atenaie-user")) {
      navigate('/home');
    }
  }, []);

  return (
    <div className="container-fluid">
      <div className="row justify-content-center mt-5">
        <h1 className="text-center h1">¡Bienvenido a Atenaie Online!</h1>
      </div>
      <div className="row mt-5">

        <div className="col-md-4 d-flex align-items-center justify-content-center">
          <div className="form-group mr-md-4">
            <h3 className="text-center mb-4 h3">Jugar como invitado</h3>
            <input
              type="text"
              className="form-control"
              placeholder="Introduce tu nombre"
              value={usernameGuest}
              onChange={handleUsernameGuestChange}
            />
            <button className="btn btn-primary mt-3 d-block mx-auto" onClick={handleGuestPlay}>
              Jugar
            </button>
          </div>
        </div>

        <div className="col-md-4 d-flex align-items-center justify-content-center">
          <div className="border-left pl-md-4 pr-md-4">

            <h2 className="text-center mb-4 h2">Como se juega</h2>

            <p> Los jugadores apuestan en secreto el número de victorias que pueden ofrecerle a la diosa Atenea.
              Durante el juego, los jugadores deben intentar cumplir con el número de victorias prometidas y evitar que los demás consigan sus objetivos.<br /><br />
              Se tiene que utilizar siempre una carta del mismo tipo que hay en el tablero o una carta especial.
              En el caso de no tener ninguna carta del mismo tipo se puede jugar la que se quiera.</p>
            <ul>
              <li>La carta de <b>Atenea</b> gana a todas las demás menos a los <b>Oráculos</b></li>
              <li>Los <b>Oráculos</b> ganan a todas las cartas excepto a los <b>Semidioses</b></li>
              <li>Los <b>Semidioses</b> ganan siempre excepto contra <b>Atenea</b></li>
              <li>Los <b>no combatientes</b> ofrecen su tesoro para no entrar en la batalla. Nunca pueden ganar</li>
              <li>En el resto de casos, ganará el mismo tipo de carta con el número más alto</li>
              <li>Si se usa un <b>Héroe</b>, vencerá a los demás tipos de carta que no sean especiales</li>
              <li>Si se lanzan varios <b>Héroes</b>, ganará el del número más alto</li>
            </ul>

          </div>
        </div>

        <div className="col-md-4 d-flex align-items-center justify-content-center">
          <div className="border-left pl-md-4 pr-md-4">
            <h3 className="text-center mb-4 h3">Iniciar sesión</h3>
            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Nombre de usuario"
                value={username}
                onChange={handleUsernameChange}
              />
            </div>
            <div className="form-group mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="Contraseña"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
            {/* <button className="btn btn-primary d-block mx-auto" onClick={handleLogin}>
              Entrar
            </button> */}
            <button disabled className="btn btn-primary d-block mx-auto" onClick={handleLogin}>
              Entrar (Próximamente)
            </button>
            <div className="text-center mt-3">
              {/* <a href="#" onClick={handleNewUser}>Crear nuevo usuario</a> */}
            </div>
          </div>
        </div>

        <div>
          {<FormNewUser showForm={showNewUserForm} closeFormNewUser={closeFormNewUser} />}
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
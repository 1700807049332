import { useEffect, useState } from 'react';
import { connectSockets, gameSocket, disconnectSockets } from '../services/WebSocketServer';
import { Player } from '../interfaces/Player';
import { Card, CardPlayed } from '../interfaces/Card';
import DashboardPlayers from '../components/DashboardPlayers';
import PlayerCards from '../components/PlayerCards';
import BetForm from '../components/BetForm';
import CardsPlayed from '../components/CardsPlayed';
import { playableCards } from '../utils/PlayableCards';
import { useLocation } from 'react-router-dom';
import { RingLoader } from 'react-spinners';
import FormEndGame from '../components/FormEndGame';
import PlayCardTimer from '../components/PlayCardTimer';
import BetTimer from '../components/BetTimer';
import ChatComponent from '../components/ChatComponent';

function GamePage() {

  const location = useLocation();
  const { userName, customGame, customRoomId } = location.state;

  const [title, setTitle] = useState('Buscando partida');
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [roomId, setRoomId] = useState('');
  const [players, setPlayers] = useState<Player[]>([]);
  const [playerCards, setPlayerCards] = useState<Card[]>([]);
  const [cardsPlayed, setCardsPlayed] = useState<CardPlayed[]>([]);
  const [cardsPlayedForPlayableFlag, setCardsPlayedForPlayableFlag] = useState<CardPlayed[]>([]);
  const [myTurn, setMyTurn] = useState(false);
  const [newRound, setNewRound] = useState(false);
  const [isEndedGame, setIsEndedGame] = useState(false);
  const [isSocketReady, setIsSocketReady] = useState(false);
  const [blockPlayerCards, setBlockPayerCards] = useState(false);

  connectSockets();

  const playCard = (idCard: number) => {
    if (gameSocket) {
      gameSocket.emit('play-card', idCard);

      setPlayerCards((playerCards) => playerCards.filter((card) => card.id !== idCard));
      setMyTurn(false);
    }
  };

  const sendBet = (bet: number) => {
    if (gameSocket) {
      gameSocket.emit('bet-round', bet);
      setNewRound(false);
    }
  };

  const onNewGame = () => {
    setIsEndedGame(false);
    setIsGameStarted(false);
    gameSocket!.emit('find-game', { name: userName });
  };

  const startCustomGame = () => {
    gameSocket!.emit('start-custom-game', { roomId: roomId });
  }

  useEffect(() => {

    if (gameSocket) {

      setIsSocketReady(true);

      if (!customGame) {
        gameSocket.emit('find-game', { name: userName });
      } else {
        if (customRoomId) {
          gameSocket.emit('find-custom-game', { name: userName, roomId: customRoomId });
        } else {
          gameSocket.emit('create-custom-game', { name: userName });

          gameSocket.on('custom-game-created', (payload) => {
            setTitle('Partida creada, esperando jugadores...');
            setPlayers(payload.players);

            setRoomId(payload.roomId);
          });
        }
      }

      gameSocket.on('found-game', (payload) => {
        setTitle('Partida encontrada, esperando jugadores...');
        setPlayers(payload.players);

        setRoomId(payload.roomId);
      });

      gameSocket.on('player-added', (payload) => {
        setPlayers((players) => [...players, payload]);
      });

      gameSocket.on('start-game', () => {
        setTitle('');
        setIsGameStarted(true);
      });

      gameSocket.on('refresh-players', (payload) => {
        setPlayers(payload);
      });

      gameSocket.on('start-round', (payload) => {
        setPlayerCards(payload);
        setNewRound(true);
      });

      gameSocket.on('start-turn', (payload) => {
        setMyTurn(payload.turn);
      });

      gameSocket.on('card-played', (payload) => {
        setCardsPlayedForPlayableFlag((cardsPlayedForPlayableFlag) => [...cardsPlayedForPlayableFlag, payload.card]);
        setCardsPlayed((cardsPlayed) => [...cardsPlayed, payload.card]);
      });

      gameSocket.on('end-turn', (payload) => {
        setBlockPayerCards(true);
        setCardsPlayed(payload);

        setCardsPlayedForPlayableFlag([]);
        setTimeout(() => {
          setCardsPlayed([]);
          setBlockPayerCards(false);
        }, 2000);
      });

      gameSocket.on('end-round', (payload) => {
        setPlayers(payload);
      });

      gameSocket.on('end-game', (payload) => {
        setTitle("La partida ha terminado");
        setPlayers(payload.playersEndGame);
        setIsEndedGame(true);
      });

      return () => {
        gameSocket!.removeAllListeners();
        disconnectSockets();
      }

    }
  }, [gameSocket]);

  useEffect(() => {
    let cardsWithPlayableFlag: Card[] = playableCards(playerCards, cardsPlayedForPlayableFlag, myTurn, blockPlayerCards);
    setPlayerCards(cardsWithPlayableFlag);
  }, [myTurn, blockPlayerCards]);

  // Manejo de la alerta de salida
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <div className="container-fluid">

      <div className="row">
        <DashboardPlayers userName={userName} players={players} roomId={roomId} />
      </div>

      <div className="row">
        <div className="col-md-8">

          <div className="row">
            {title && (<h1 className="text-center mt-4 mb-4">{title}</h1>)}
          </div>

          <div className="row">
            {!isGameStarted && (
              <div className="d-flex flex-column align-items-center mt-4">
                <RingLoader
                  color="#123456"
                  size={350}
                  speedMultiplier={0.8}
                />
              </div>
            )}

            {(customGame && !customRoomId && !isGameStarted) && (
              <div className="d-flex flex-column align-items-center mt-4">
                <button className="btn btn-primary align-items-center" onClick={() => startCustomGame()}>
                  ¡Empezar partida!
                </button>
              </div>
            )}
          </div>

          <div className="row">
            <div>
              <PlayCardTimer myTurn={myTurn} playCard={playCard} playerCards={playerCards} />
            </div>

            <div>
              <BetTimer newRound={newRound} sendBet={sendBet} />
            </div>

            <div>
              <BetForm newRound={newRound} sendBet={sendBet} numPlayerCards={playerCards.length} />
            </div>
          </div>

          <div className="row">
            <div className="text-center">
              <CardsPlayed cardsPlayed={cardsPlayed} newRound={newRound} isGameStarted={isGameStarted} />
            </div>
          </div>

          <div className="row">
            <div>
              <PlayerCards cards={playerCards} playCard={playCard} isGameStarted={isGameStarted} />
            </div>
          </div>
        </div>

        <div className="col-md-4">
          {isSocketReady && <ChatComponent userName={userName} roomId={roomId} socket={gameSocket!} />}
        </div>

        <FormEndGame players={players} onNewGame={onNewGame} isEndedGame={isEndedGame} />

      </div>
    </div>
  );
}

export default GamePage;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { newUser } from '../services/RestServer';

interface Props {
    showForm: boolean;
    closeFormNewUser: () => void;
}

const FormEndGame: React.FC<Props> = ({ showForm, closeFormNewUser }) => {

    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onSave = () => {
        if (password === passwordRepeat) {
            newUser(username, password);
        } else {
            alert("Las contraseñas no coinciden");
        }
        
        onExit();
    };

    useEffect(() => {
        setIsModalOpen(showForm);
    }, [showForm]);

    const onExit = () => {
        closeFormNewUser();
        setIsModalOpen(false);
        setUsername('');
        setPassword('');
        setPasswordRepeat('');
    };

    return (
        <>
            <Modal show={isModalOpen} onHide={onExit}>
                <Modal.Header closeButton onClick={onExit}>
                    <Modal.Title>Crear usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="d-flex align-items-center justify-content-center">
                        <div className="border-left pl-md-4 pr-md-4">
                            <div className="form-group mb-3">
                                <label>Nombre de usuario</label>
                            </div>
                            <div className="form-group mb-3">
                                <input
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>Contraseña</label>
                            </div>
                            <div className="form-group mb-3">
                                <input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>Repetir Contraseña</label>
                            </div>
                            <div className="form-group mb-3">
                                <input
                                    type="password"
                                    value={passwordRepeat}
                                    onChange={(e) => setPasswordRepeat(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={onSave}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default FormEndGame;

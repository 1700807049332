import React from 'react';
import { CardPlayed } from '../interfaces/Card';
import { getImageByCardType, getColorButton } from '../utils/PrintCard';

interface CardsPlayedProps {
    cardsPlayed: CardPlayed[];
    newRound: boolean;
    isGameStarted: boolean;
}

const CardsPlayed = ({ cardsPlayed, newRound, isGameStarted }: CardsPlayedProps) => {

    return (
        <div>
            {(!newRound && isGameStarted && cardsPlayed.length == 0) && (<h3 className="text-center mb-4">Aún no se ha jugado ninguna carta</h3>)}
            {cardsPlayed.length > 0 && cardsPlayed.map((card: CardPlayed, index) => (
                <div style={{
                    backgroundColor: getColorButton(card.color),
                    border: card.isWinnerTurnCard ? '4px solid black' : 'none'
                }}
                    key={index} className="btn btn-primary card-button">
                    {/* <div className="d-flex flex-column align-items-center"> */}
                    <div className="d-flex flex-column align-items-center">
                        <img src={getImageByCardType(card.type)} className="mb-2" alt={card.description} />
                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{card.description} {!card.special ? card.number : ''}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CardsPlayed;
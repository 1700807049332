import React, { useState, useEffect, useRef } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';

const BET_TIME = 50;

interface BetTimerProps {
    newRound: boolean;
    sendBet: (bet: number) => void;
}

const BetTimer: React.FC<BetTimerProps> = ({ newRound, sendBet }) => {
    const [timeRemaining, setTimeRemaining] = useState(BET_TIME);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        setTimeRemaining(BET_TIME);

        if (newRound) {
            // Limpiar cualquier intervalo existente antes de iniciar uno nuevo
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }

            intervalRef.current = setInterval(() => {
                setTimeRemaining(prevTime => prevTime - 1);
            }, 1000);
        }

        // Función de limpieza
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [newRound]);

    useEffect(() => {
        if (timeRemaining === 0 && newRound) {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
            sendBet(0);
        }
    }, [timeRemaining, sendBet]);

    return (
        <div>
            {newRound && (
                <div className="mt-3 mb-3">
                    <ProgressBar animated min={0} max={BET_TIME} now={timeRemaining} label={`${timeRemaining} segundos`} />
                </div>
            )}
        </div>
    );
};

export default BetTimer;
import { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Form, Button, ListGroup } from 'react-bootstrap';
import { Socket } from 'socket.io-client';

interface ChatComponentProps {
    userName: string;
    roomId: string;
    socket: Socket;
}

const ChatComponent = ({ userName, roomId, socket }: ChatComponentProps) => {
    const [messages, setMessages] = useState<string[]>([]);
    const [newMessage, setNewMessage] = useState('');

    // Referencia para el contenedor de los mensajes
    const messagesEndRef = useRef<HTMLDivElement>(null);

    const handleInputChange = (event: any) => {
        setNewMessage(event.target.value);
    };

    const handleSendMessage = (event: any) => {
        event.preventDefault();
        if (newMessage.trim() !== '') {
            socket.emit('send-chat-message', { userName, roomId, newMessage });
            const updatedMessages = [...messages, ('<strong>Tú: </strong>' + newMessage)];
            setMessages(updatedMessages);
            setNewMessage('');
        }
    };

    useEffect(() => {

        const infoChatMessage = `
            <h5>Como se juega:</h5>
            <ul>
                <li><strong>Objetivo:</strong> Adivina cuántas rondas (o "bazas") vas a ganar en cada turno. Ganas puntos si aciertas tu predicción (apuesta).</li>
                <li><strong>Cartas:</strong> Hay cartas numeradas (del 1 al 14) y cartas especiales: Semidioses, Oráculos y Atenea</li>
                <li><strong>Rondas:</strong> En la primera ronda, recibes 1 carta, en la segunda 2 cartas, y así sucesivamente.</li>
                <li><strong>Apuesta (Predicción):</strong> Al comienzo de cada ronda, debes decir cuántas bazas crees que ganarás (puede ser 0 si no crees ganar ninguna).</li>
                <li><strong>Juego:</strong> Todos juegan una carta por turno. La carta con el número más alto gana la baza, a menos que alguien juegue un Semidiós (que gana siempre menos a Atenea), un Oráculo (que gana siempre menos a los Semidioses), o Atenea (que gana siempre menos a los Oráculos).</li>
                <li><strong>Cartas no especiales:</strong> Para las cartas no especiales, manda el tipo (color) de la que se haya jugado primero, se tiene que jugar una carta del mismo tipo o una especial, sino se tiene del mismo tipo se puede jugar la que se quiera, los héroes ganan a las demás cartas numéricas.</li>
                <li><strong>Puntuación:</strong> Si aciertas cuántas bazas ganaste, sumas puntos; si fallas, pierdes puntos.</li>
            </ul>
        `;

        setMessages([infoChatMessage]);

    }, []);

    useEffect(() => {

        socket.on('receive-chat-message', (payload) => {
            const updatedMessages = [...messages, `<strong>${payload.userName}: </strong>` + payload.newMessage];
            setMessages(updatedMessages);
        });

    }, [messages]);

    // Desplazar el scroll hacia abajo al final de los mensajes
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    return (
        <Container>
            <Row>
                <Col>
                    <h1 className="text-center mt-4 mb-4">Chat</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ListGroup style={{ maxHeight: '600px', overflowY: 'auto' }}>
                        {messages.map((message, index) => (
                            // TODO: Evitar dangerouslySetInnerHTML (Se usa para dar formato al primer mensaje de Como se Juega)
                            <ListGroup.Item key={index}>
                                <div dangerouslySetInnerHTML={{ __html: message }} />
                            </ListGroup.Item>
                        ))}
                        {/* Este div invisible se utiliza como referencia para el scroll */}
                        <div ref={messagesEndRef}></div>
                    </ListGroup>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col sm={10}>
                    <Form.Control
                        type="text"
                        placeholder="Escribe tu mensaje..."
                        value={newMessage}
                        onChange={handleInputChange}
                    />
                </Col>
                <Col sm={2}>
                    <Button variant="primary" onClick={handleSendMessage}>
                        Enviar
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default ChatComponent;